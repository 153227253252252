import { Helmet } from 'react-helmet-async';
// @mui
import {
  TextField,
  Grid,
  Button,
  Container,
  Stack,
  Typography,
  Card,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { addDoc, collection, doc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

export default function NewDnsPage({ isEdit = false, item = {} }) {
  const [details, setDetails] = useState({
    Username: '',
    Password: '',
    IPaddress: '',
    active: true,
    Port: '',
    Local: '',
  });
  const navigate = useNavigate();
  const location = useLocation();
  const selectedItem = location.state?.selectedItem;
  const updateDNSRecord = async (recordId, updatedRecord) => {
    const isEmptyField = Object.values(updatedRecord).some((value) => value === '');

    if (isEmptyField) {
      alert('Error: Empty field detected in the record');
      return;
    }
    try {
      const collectionRef = collection(db, 'DNS servers');
      const recordRef = doc(collectionRef, recordId);
      await updateDoc(recordRef, updatedRecord);
      alert('DNS record updated successfully');
    } catch (error) {
      console.error('Error updating DNS record: ', error);
    }
  };
  useEffect(() => {
    if (selectedItem) {
      console.log(selectedItem);
      setDetails({
        Username: selectedItem?.Username,
        Password: selectedItem?.Password,
        IPaddress: selectedItem?.IPaddress,
        active: selectedItem?.active,
        Port: selectedItem?.Port,
        Local: selectedItem?.Local,
      });
    }
  }, []);

  const addDNSRecord = async (record) => {
    const isEmptyField = Object.values(record).some((value) => value === '');

    if (isEmptyField) {
      alert('Error: Empty field detected in the record');
      return;
    }
    try {
      const collectionRef = collection(db, 'DNS servers');
      const res = await addDoc(collectionRef, record);
      navigate('/dashboard/dns');
    } catch (error) {
      console.error('Error adding DNS record: ', error);
    }
  };

  return (
    <>
      <Helmet>
        <title> DNS Management </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Add New DNS
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Add New DNS
          </Button> */}
        </Stack>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                value={details.Username}
                onChange={(e) => {
                  setDetails({ ...details, Username: e.target.value });
                }}
                required
                label="Username"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={details.Password}
                onChange={(e) => {
                  setDetails({ ...details, Password: e.target.value });
                }}
                required
                label="Password"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={details.IPaddress}
                onChange={(e) => {
                  setDetails({ ...details, IPaddress: e.target.value });
                }}
                required
                label="IP Address"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={details.Port}
                onChange={(e) => {
                  setDetails({ ...details, Port: e.target.value });
                }}
                required
                label="Port"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={details.Local}
                onChange={(e) => {
                  setDetails({ ...details, Local: e.target.value });
                }}
                required
                label="Location"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked={details.active}
                      onChange={(e) => {
                        setDetails({ ...details, active: e.target.checked });
                      }}
                    />
                  }
                  label="Active"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={() => {
                  if (selectedItem) {
                    updateDNSRecord(selectedItem?.id, details);
                  } else {
                    addDNSRecord(details);
                  }
                }}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}
