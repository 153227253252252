import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { useDispatch } from 'react-redux';
// @mui
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { auth, db } from '../../../firebase';
import { login } from '../../../redux/slices/auth/authSlice';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [details, setDetails] = useState({
    email: '',
    password: '',
  });

  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const handleClick = () => {
    if (details.email !== '' && details.password !== '') {
      signInWithEmailAndPassword(auth, details.email, details.password)
        .then(async (userCredential) => {
          // Signed in
          const user = userCredential.user;
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          const userType = userDoc.data().user?.type;

          if (userType === 'user') {
            alert('regular user detected. Login not allowed.');
            // Sign out the user to prevent them from accessing the app
            await signOut(auth);
            navigate('/', { replace: true });
          } else {
            // User is not an admin, continue with login
            console.log('Regular user logged in.');
            dispatch(login(user));
            navigate('/dashboard', { replace: true });
          }
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          if (error.code === 'auth/user-not-found') {
            alert('No user found with this email');
          } else {
            alert(errorCode === 'auth/wrong-password' ? 'Wrong password' : errorCode);
          }
        });
    } else {
      alert('Fill both fields');
    }
    //
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          value={details.email}
          type={'email'}
          onChange={(e) => {
            setDetails({
              ...details,
              email: e.target.value,
            });
          }}
          name="email"
          label="Email address"
        />

        <TextField
          name="password"
          label="Password"
          value={details.password}
          onChange={(e) =>
            setDetails({
              ...details,
              password: e.target.value,
            })
          }
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />

        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}
      <div style={{ height: 20 }} />
      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
