import { Route, Routes, Navigate, useRoutes } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import { login } from './redux/slices/auth/authSlice';
import DNSPage from './pages/DnsPage';
import NewDnsPage from './pages/NewDnsPage';
import Subscriptions from './pages/Subscriptions';

// ----------------------------------------------------------------------

export default function Router() {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(login(user));
        setLoggedIn(true);
        setLoading(false);
      } else {
        setLoggedIn(false);
        setLoading(false);
      }
    });
  }, []);
  let routes = null;

  routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
  if (loading) {
    return (
      <div style={{ flex: 1, alignItems: 'center', width: '100%' }}>
        <h1>Loading...</h1>
      </div>
    );
  }
  return (
    <Routes>
      <Route path="/" element={loggedIn ? <Navigate to="/dashboard" /> : <LoginPage />} />
      <Route path="/login" element={<LoginPage />} />
      {loggedIn ? (
        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route path="" element={<Navigate to="app" replace />} />

          <Route path="app" element={<DashboardAppPage />} />
          <Route path="user" element={<UserPage />} />
          <Route path="products" element={<ProductsPage />} />
          <Route path="dns" element={<DNSPage />} />
          <Route path="add-dns" element={<NewDnsPage />} />
          <Route path="subscriptions" element={<Subscriptions />} />
        </Route>
      ) : null}
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
