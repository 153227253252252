import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDFRj8pJYIH-nZ9kF_pJLl-iR6pE8IA3ug',

  authDomain: 'tvpassport-65eb2.firebaseapp.com',

  projectId: 'tvpassport-65eb2',

  storageBucket: 'tvpassport-65eb2.appspot.com',

  messagingSenderId: '987352101766',

  appId: '1:987352101766:web:c8acb86ab61fe0c27af207',

  measurementId: 'G-57T4QMZJX4',
};
// Initialize Firebase

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);

export const auth = getAuth();
